import React, { useContext } from 'react';
import { MenuContext } from '../../../context'

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data'

const Article = () => {
  const menuContext = useContext(MenuContext);

  return (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Thursday 4th March 2021, we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60% and 90% LTV have reduced</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 90% LTV have reduced</li>
        <li>5 Year Fixed Standard at 60% and 90% LTV have reduced</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have reduced</li>
        <li>5 Year Fixed Premier Exclusive at 60% and 90% LTV have reduced</li>
      </ul>
      <p>Please note that our 95% LTV range is still only available for existing HSBC mortgage customers switching rates and not new business.</p>
      <p>You can browse the range of HSBC mortgages using our <a href="/products/product-finder.html" inert={menuContext.isMenuOpen ? "true" : null}>product finder</a> or by downloading our <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>latest rate sheet<span className="accessible">&nbsp;This PDF opens in a new window</span></a>.</p>
    </NewsArticle>
  )
}

export default Article
